import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import i18next from 'i18next';

import {
  openConfirmationModal,
  removeProductVersionFromActivityModal,
  openAddUsersModal,
  openAddProfileRatersModal,
  openGenerateProfileModal,
  openUpdateUsersModal,
  openAddGroupsModal,
  closeTopModal,
  openModal,
} from '^/actions/modals';
import { updateActivity } from '^/actions/collections';
import { getStatus, ACTIVITY_STATUS } from '^/activityStatus';
import ContentBox from '^/components/ContentBox';
import Loading from '^/components/Loading';
import ActivityDetail from './ActivityDetail';
import { PRODUCT_ACTIVITY_TYPES } from '^/components/productVersions/choices';
import RespondentsTab from './tabs/RespondentsTab';
import { ACTIVITY_TYPE } from '^/models/activity';
import ActivityReportsTab from './tabs/ActivityReportsTab';
import ActivityNotificationsTab from './tabs/ActivityNotificationsTab';
import ActivitySentNotificationsTab from './tabs/ActivitySentNotificationsTab';
import ActivityNominationRulesTab from './tabs/nomination-rules/ActivityNominationRulesTab';
import ActivityRatersStatusTable from './ActivityRatersStatusTable';
import CompletionSummaryTable from './CompletionSummaryTable';
import ContentTabset from '^/components/ContentTabset';
import ContentTab from '^/components/ContentTab';
import PureComponent from '^/components/PureComponent';
import {
  addNominationRule,
  closePaneset,
  deleteNominationRule,
  muteActivityNotification,
  togglePane,
  updateActivityReportVisibility,
  updateActivityUser,
} from '../../actions/actions';
import {
  addUsersToActivityAndRefresh,
  addProfileCreatorToActivityAndRefresh,
  addGroupsToActivityAndRefresh,
  closeModalAndApplyTemplate,
  closeModalAndUpdateActivity,
  removeUserFromActivityAndRefresh,
} from '../../actions/actionSequences';

export class Activity extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.closePaneset(this.getPaneset());
  }

  getPaneset() {
    return 'activity:' + this.getId();
  }

  getPaneState(pane) {
    const paneset = this.getPaneset();
    return {
      toggle: () => this.props.togglePane(paneset, pane),
      isOpen: this.props.expandedPaneSets.get(paneset) === pane,
    };
  }

  getId() {
    return this.props.activitySummary.get('id');
  }

  updateField(fieldName, value) {
    this.props.updateActivity(this.getId(), { [fieldName]: value });
  }

  updateFields(namesAndValues) {
    this.props.updateActivity(this.getId(), namesAndValues);
  }

  confirmUpdateField(message, fieldSpec) {
    this.props.openConfirmationModal(
      i18next.t('make reports available for'),
      i18next.t('activity'),
      [message],
      () => {
        this.updateField.apply(this, fieldSpec);
        this.props.closeTopModal();
      },
      this.props.closeTopModal,
      'btn-warning'
    );
  }

  showRaterStatusTable() {
    this.props.push(`/page/activities/${this.getId()}/raters`);
  }

  needsRaters(activity) {
    const type = activity.get('type'),
      variant = activity.getIn(['activity360', 'variant']);

    return (
      type === 'THREE_SIXTY' &&
      variant !== 'ONE_EIGHTY' &&
      variant !== 'SELF_RATED'
    );
  }

  showRaterInfo(activity) {
    const type = activity.get('type'),
      variant = activity.getIn(['activity360', 'variant']);

    return type === 'THREE_SIXTY' && variant !== 'SELF_RATED';
  }

  renderOrgLogo(organisation) {
    return organisation.get('brand_logo') ? (
      <div className="text-right">
        <img className="small-logo" src={organisation.get('brand_logo')} />
      </div>
    ) : (
      <div className="text-right">{organisation.get('name')}</div>
    );
  }

  render() {
    const {
      activity,
      activitySummary,
      activityRespondents,
      user,
      response,
      addUsersResponse,
      removeUserResponse,
      addGroupsResponse,
      nominationRulesResponse,
      updateReportVisibilityResponse,
    } = this.props;
    const profileId = activity && activity.get('profile').get('id');
    let updatedActivitySummary = activitySummary;

    if (activitySummary.get('with_pulse', false)) {
      updatedActivitySummary = updatedActivitySummary.updateIn(
        ['activity_product_version_items'],
        productVersions =>
          productVersions.map(productVersion =>
            productVersion.updateIn(
              ['product_version', 'product', 'name'],
              name => `${name} + ${i18next.t('Pulsing')}`
            )
          )
      );
    }
    const {
      id,
      start_datetime,
      end_datetime,
      product_versions: productVersions,
      type,
      created,
      created_by_user,
      has_notifications,
    } = updatedActivitySummary.toObject();

    const isProfiler = type === ACTIVITY_TYPE.PROFILER;
    const activityProductVersion =
      activity && activity.get('activity_product_version_items').first();
    const isDISC = Boolean(
      activityProductVersion &&
        activityProductVersion.getIn(['product_version', 'product', 'name']) ===
          PRODUCT_ACTIVITY_TYPES.DISPLAY.PROFILER_DISC
    );
    const isGenerated = activity?.getIn([
      'profile',
      'success_profile',
      'is_generated',
    ]);
    const successVersionType = activity?.getIn([
      'profile',
      'success_profile',
      'version_type',
    ]);

    const hasStartAndEnd = start_datetime && end_datetime,
      status = getStatus(activitySummary),
      userName = user.get('full_name'),
      needsRaters = this.needsRaters(activitySummary),
      showRaterInfo = this.showRaterInfo(activitySummary);
    const readOnly =
      created_by_user.get('id') !== user.get('id') &&
      user.get('has_restricted_sharing_permissions', false);
    const isLiveOrEnded = [
      ACTIVITY_STATUS.LIVE,
      ACTIVITY_STATUS.ENDED,
    ].includes(status);

    const profileCreator =
      isProfiler && activityRespondents
        ? activityRespondents.get('users').get(0)
        : null;

    return (
      <div className="activity-page">
        <ActivityDetail
          activity={activitySummary}
          response={response}
          needsRaters={needsRaters}
          readOnly={readOnly}
          isLiveOrEnded={isLiveOrEnded}
          createdOn={created}
          prepayUrl={activitySummary.get('prepay_url')}
          profileCreator={profileCreator}
          successVersionType={successVersionType}
        />

        <div className="surveys">
          <div>
            <ContentTabset
              name="activity-tabs"
              defaultActiveTab={0}
              contentWrapper={ContentBox}
            >
              <ContentTab
                tabId={0}
                tabsetName="activity-tabs"
                tabName={
                  isProfiler
                    ? i18next.t('Profile raters')
                    : i18next.t('Respondents')
                }
              >
                {activityRespondents ? (
                  <RespondentsTab
                    user={user}
                    isProfiler={isProfiler}
                    isDISC={isDISC}
                    profileId={profileId}
                    isGenerated={isGenerated}
                    successVersionType={successVersionType}
                    activity={activityRespondents}
                    activitySummary={activitySummary}
                    activityId={id}
                    status={status}
                    organisation={activityRespondents.get('organisation')}
                    addProfileCreatorToActivityAndRefresh={
                      this.props.addProfileCreatorToActivityAndRefresh
                    }
                    addUsersToActivityAndRefresh={
                      this.props.addUsersToActivityAndRefresh
                    }
                    addGroupsToActivityAndRefresh={
                      this.props.addGroupsToActivityAndRefresh
                    }
                    openConfirmationModal={this.props.openConfirmationModal}
                    openUpdateUsersModal={this.props.openUpdateUsersModal}
                    closeTopModal={this.props.closeTopModal}
                    openAddUsersModal={this.props.openAddUsersModal}
                    openAddProfileRatersModal={
                      this.props.openAddProfileRatersModal
                    }
                    openGenerateProfileModal={
                      this.props.openGenerateProfileModal
                    }
                    openAddGroupsModal={this.props.openAddGroupsModal}
                    removeUserFromActivityAndRefresh={
                      this.props.removeUserFromActivityAndRefresh
                    }
                    updateActivity={this.props.updateActivity}
                    closeModalAndUpdateActivity={
                      this.props.closeModalAndUpdateActivity
                    }
                    response={response}
                    hasProductVersions={
                      productVersions && !productVersions.isEmpty()
                    }
                    activityUsers={activityRespondents.get('users')}
                    showRaterInfo={showRaterInfo}
                    userName={userName}
                    addUsersResponse={addUsersResponse}
                    addGroupsResponse={addGroupsResponse}
                    removeUserResponse={removeUserResponse}
                    isPrepay={activityRespondents.get('is_prepay')}
                    canBePrepay={type !== 'THREE_SIXTY'}
                    prepayUrl={activityRespondents.get('prepay_url')}
                    updateActivityUser={this.props.updateActivityUser}
                    showRaterStatusTable={() => this.showRaterStatusTable()}
                    ratersNeedApproval={
                      !!activityRespondents.getIn([
                        'activity360',
                        'line_manager_must_approve_raters',
                      ])
                    }
                    readOnly={readOnly}
                  />
                ) : (
                  <Loading />
                )}
              </ContentTab>

              {needsRaters && (
                <ContentTab
                  tabId={1}
                  tabsetName="activity-tabs"
                  tabName={i18next.t('Nomination rules')}
                >
                  {activity ? (
                    <ActivityNominationRulesTab
                      allRoles={activity
                        .get('product_versions')
                        .flatMap(each =>
                          each.getIn(['productversion360', 'roles'], List())
                        )}
                      createNominationRule={this.props.addNominationRule.bind(
                        this,
                        id
                      )}
                      deleteRule={this.props.deleteNominationRule.bind(
                        this,
                        id
                      )}
                      response={nominationRulesResponse}
                      activityResponse={response}
                      activity={activity}
                      updateFields={this.updateFields.bind(this)}
                      readOnly={readOnly}
                    />
                  ) : (
                    <Loading />
                  )}
                </ContentTab>
              )}

              <ContentTab
                tabId={2}
                tabsetName="activity-tabs"
                tabName={i18next.t('Notifications settings')}
              >
                {activity ? (
                  <ActivityNotificationsTab
                    activityId={id}
                    notification_emails={activity.get('notification_emails')}
                    activity={activity}
                    hasStartAndEnd={hasStartAndEnd}
                    updateActivity={this.props.updateActivity}
                    response={response}
                    muteNotificationsResponse={
                      this.props.muteNotificationsResponse
                    }
                    readOnly={readOnly}
                  />
                ) : (
                  <Loading />
                )}
              </ContentTab>
              {!isProfiler && (
                <ContentTab
                  tabId={3}
                  tabsetName="activity-tabs"
                  tabName={i18next.t('Report options')}
                >
                  {activity ? (
                    <ActivityReportsTab
                      activity={activity}
                      updateReportVisibility={this.props.updateActivityReportVisibility.bind(
                        null,
                        id
                      )}
                      updateReportVisibilityResponse={
                        updateReportVisibilityResponse
                      }
                      muteActivityNotification={this.props.muteActivityNotification.bind(
                        null,
                        id
                      )}
                      readOnly={readOnly}
                      updateFields={this.updateFields.bind(this)}
                    />
                  ) : (
                    <Loading />
                  )}
                </ContentTab>
              )}

              {needsRaters && (
                <ContentTab
                  tabId={4}
                  tabsetName="activity-tabs"
                  tabName={i18next.t('Raters')}
                >
                  <p>{i18next.t('Search for a respondent or rater')}</p>

                  {activity ? (
                    <ActivityRatersStatusTable
                      activity={activity}
                      readOnly={readOnly}
                    />
                  ) : (
                    <Loading />
                  )}
                </ContentTab>
              )}

              {has_notifications && (
                <ContentTab
                  tabId={5}
                  tabsetName="activity-tabs"
                  tabName={i18next.t('Sent notifications')}
                >
                  {activity ? (
                    <ActivitySentNotificationsTab
                      activity={activity}
                      readOnly={readOnly}
                    />
                  ) : (
                    <Loading />
                  )}
                </ContentTab>
              )}

              {needsRaters && (
                <ContentTab
                  tabId={6}
                  tabsetName="activity-tabs"
                  tabName={i18next.t('Completion summary')}
                >
                  {activity ? (
                    <CompletionSummaryTable
                      activity={activity}
                      readOnly={readOnly}
                    />
                  ) : (
                    <Loading />
                  )}
                </ContentTab>
              )}
            </ContentTabset>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    user: state.userProfile,
    response: state.responses.get('updateItem'),
    addUsersResponse: state.responses.get('addUsersToActivity'),
    addGroupsResponse: state.responses.get('addGroupsToActivity'),
    removeUserResponse: state.responses.get('removeUserFromActivity'),
    expandedPaneSets: state.ui.get('expandedPaneSets'),
    nominationRulesResponse: state.responses.get('nominationRules'),
    muteNotificationsResponse: state.responses.get('muteActivityNotification'),
    updateReportVisibilityResponse: state.responses.get(
      'updateActivityReportVisibility'
    ),
  };
}

Activity.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
  activity: ImmutablePropTypes.map.isRequired,
  activitySummary: ImmutablePropTypes.map.isRequired,
  activityRespondents: ImmutablePropTypes.map.isRequired,
  muteActivityNotification: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  removeProductVersionFromActivityModal,
  openModal,
  openConfirmationModal,
  openAddUsersModal,
  openAddProfileRatersModal,
  openGenerateProfileModal,
  openUpdateUsersModal,
  openAddGroupsModal,
  closeTopModal,
  togglePane,
  closePaneset,
  closeModalAndUpdateActivity,
  addUsersToActivityAndRefresh,
  addProfileCreatorToActivityAndRefresh,
  addGroupsToActivityAndRefresh,
  removeUserFromActivityAndRefresh,
  addNominationRule,
  deleteNominationRule,
  updateActivity,
  updateActivityUser,
  push,
  updateActivityReportVisibility,
  muteActivityNotification,
  closeModalAndApplyTemplate,
})(Activity);
