/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { push, replace } from 'react-router-redux';
import { fromJS, Map, List, Set } from 'immutable';
import _ from 'underscore';
import moment from 'moment';
import i18next from 'i18next';

import * as actions from './actions';
import * as modals from './modals';
import * as collections from './collections';
import * as items from './items';
import * as shop from './shop';
import { USER_ROLES } from '^/models/user';
import {
  getProductVersionNameFromQuestionType,
  shuffleImmutableList,
} from '../utils';
import { moveTextQuestionsToEnd } from '../productVersions';
import { getAdditionalWelcomeScreens } from '^/models/product';
import makeActionChain from './makeActionChain';
import { getConsentUrl, getLangCodeWithFallback } from '^/utils-ts';
import { MODALS } from '^/components/modals/types';
import { CONSENT_STATES } from '^/components/consent/constants';
import {
  PRODUCT_TYPES,
  PRODUCT_ACTIVITY_TYPES,
} from '^/components/productVersions/choices';
import {
  setActiveLanguage,
  setInputTextLanguage,
  setLanguageSwitcherInteractedWith,
  setUiLanguage,
} from './ui';
import { can, seeAdminDashboard } from '^/capabilities';
import {
  DEFAULT_LANGUAGE_CODE,
  TRANSLATED_PATHS_ONLY_FOR_SWITCHABLE_USERS,
} from '^/constants/routes';
import {
  PERSPECTIVES_COMPETENCIES_LIKERT,
  PERSPECTIVES_COMPETENCIES_NUMBER_OF_QUESTIONS,
  PERSPECTIVES_COMPETENCIES_COLLECTION_INDEX,
  PROFILE_MOTIVATORS_INDEX,
} from '^/constants/assessments';

export const loadAndSetActiveLanguage = (langCode, url) => {
  const safeLangCode = getLangCodeWithFallback(langCode);
  i18next.changeLanguage(safeLangCode);
  moment.locale(safeLangCode);
  const actionSequence = [() => setActiveLanguage(safeLangCode)];
  if (url) {
    actionSequence.push(() => replace(url));
  }
  return makeActionChain(actionSequence);
};

export const setLanguageAndReload = (langCode, url) => {
  const safeLangCode = getLangCodeWithFallback(langCode);
  i18next.changeLanguage(safeLangCode);
  moment.locale(safeLangCode);
  const actionSequence = [
    () => setUiLanguage(safeLangCode),
    () => loadAndSetActiveLanguage(safeLangCode),
  ];
  if (url) {
    actionSequence.push(() => replace(url));
  }
  return makeActionChain(actionSequence);
};

export const resetLanguageAndCloseTopModal = language => {
  return makeActionChain([
    () => loadAndSetActiveLanguage(language),
    () => modals.closeTopModal(),
  ]);
};

export const setCanResumeAndResetLanguageAndCloseTopModal = (
  language,
  activityId,
  productVersionId
) => {
  return makeActionChain([
    () => loadAndSetActiveLanguage(language),
    () =>
      actions.updateActivityProductVersionSession(
        activityId,
        productVersionId,
        null,
        { can_resume: true }
      ),
    () => collections.getAllMyActivities(),
    () => modals.closeTopModal(),
  ]);
};

export const resetLanguageAndCloseAllModals = language => {
  return makeActionChain([
    () => loadAndSetActiveLanguage(language),
    () => modals.closeAllModals(),
  ]);
};

const userInfoCheckComplete = ({ error, payload }) => dispatch => {
  if (error) {
    return dispatch(actions.logout(payload.response));
  }

  if (
    payload &&
    !payload.has_completed_details &&
    payload.role !== USER_ROLES.EXTERNAL
  ) {
    //Arf! Incomplete user! We need to ask for the Complete Details modal
    dispatch(modals.openAdditionalDetailsModal(Map(payload)));
  }
};

export function openProductVersion(
  productVersion,
  activity,
  raterFor,
  questionCollectionIdx = 0,
  currentMAPQuestionIdx = 0,
  ranking = false
) {
  const productVersionType = productVersion
    ? productVersion.get('type', 'productVersion')
    : 'productVersion';
  const productVersionName = getProductVersionNameFromQuestionType(
    productVersionType
  );
  const unloadMessage =
    productVersionType === 'THREE_SIXTY'
      ? i18next.t(
          'If you need to come back to finish the questionnaire, you will be able to return to where you left off.'
        )
      : i18next.t('If you exit your {{productVersionName}} now, your answers', {
          productVersionName,
        });
  return makeActionChain([
    () => actions.setMAPProductVersionQuestionIndex(currentMAPQuestionIdx),
    () =>
      modals.openProductVersionModal(
        productVersion,
        activity,
        raterFor,
        questionCollectionIdx,
        ranking
      ),
    () => actions.onBeforeUnload(unloadMessage),
    () => actions.scrollTop('.full-screen-overlay'),
  ]);
}

export function startProductVersion(
  productVersion,
  activity,
  raterFor,
  questionCollectionIdx = 0
) {
  productVersion = productVersion.update(
    'questioncollection_set',
    questionCollections =>
      questionCollections &&
      questionCollections.map(questionCollection =>
        questionCollection.get('is_random')
          ? questionCollection
              .set(
                'questions',
                shuffleImmutableList(
                  questionCollection.get('questions', List())
                )
              )
              .set(
                'question_groups',
                shuffleImmutableList(
                  questionCollection.get('question_groups', List())
                )
              )
          : questionCollection
      )
  );

  // If we're a 360, text questions must ALWAYS go to the end
  if (productVersion.getIn(['product', 'activity_type']) === 'THREE_SIXTY') {
    productVersion = moveTextQuestionsToEnd(productVersion);
  }
  return makeActionChain([
    () => actions.clearAnswers(),
    () =>
      openProductVersion(
        productVersion,
        activity,
        raterFor,
        questionCollectionIdx
      ),
  ]);
}

export const getAndCheckUserInfo = (
  updateTranslation,
  nextPath,
  currentLanguage
) => dispatch => {
  dispatch(actions.getUserInfo())
    .then(responseAction => {
      if (responseAction.type === actions.GET_USER_INFO.SUCCESS) {
        const { consent_state, lang_code, role } = responseAction.payload;
        lang_code
          ? dispatch(setUiLanguage(getLangCodeWithFallback(lang_code)))
          : dispatch(actions.updateUserLanguage(currentLanguage));
        if (
          role !== USER_ROLES.EXTERNAL &&
          (!consent_state || consent_state !== CONSENT_STATES.AGREED)
        ) {
          return lang_code
            ? dispatch(setLanguageAndReload(lang_code, getConsentUrl()))
            : dispatch(push(getConsentUrl()));
        }
      }
      return responseAction;
    })
    .then(action => dispatch(userInfoCheckComplete(action)))
    .then(() => updateTranslation())
    // eslint-disable-next-line no-console
    .catch(error => console.error(error));
};

export const checkUserInfoAndLogin = redirectPath => dispatch => {
  return (
    dispatch(actions.getUserInfo())
      .then(action => dispatch(userInfoCheckComplete(action)))
      .then(() => dispatch(push(redirectPath)))
      // eslint-disable-next-line no-console
      .catch(error => console.error(error))
  );
};

export function redeemInviteAndCheckUserInfo(
  inviteId,
  email,
  fullName,
  password,
  password2,
  uiLanguage,
  userPulseId
) {
  return dispatch => {
    return dispatch(
      actions.redeemInvite(
        inviteId,
        email,
        fullName,
        password,
        password2,
        uiLanguage
      )
    ).then(responseAction => {
      // We should only continue the chain on a success
      if (responseAction.type !== actions.REDEEM_INVITE.SUCCESS) {
        return Promise.reject(responseAction);
      }
      const userInfo = responseAction.payload;
      if (userInfo && userInfo.consent_state !== CONSENT_STATES.AGREED) {
        return dispatch(
          setLanguageAndReload(userInfo.lang_code, getConsentUrl(userPulseId))
        );
      }
      userPulseId
        ? dispatch(
            setLanguageAndReload(
              userInfo.lang_code,
              `/#/user-pulses/${userPulseId}`
            )
          )
        : dispatch(setLanguageAndReload(userInfo.lang_code, '/page/home/'));
      if (
        !userInfo.has_completed_details &&
        userInfo.role !== USER_ROLES.EXTERNAL
      ) {
        //Arf! Incomplete user! We need to ask for the Complete Details modal
        return dispatch(modals.openAdditionalDetailsModal(Map(userInfo)));
      }
    });
  };
}

export function clearTokenAndLogin(
  email,
  password,
  activity,
  userPulse,
  currentLanguage,
  switcherInteractedWith
) {
  const processUserDetails = response => {
    const savedUserLang = getLangCodeWithFallback(response.lang_code);
    const loginLanguage = switcherInteractedWith
      ? currentLanguage
      : savedUserLang;
    const url = userPulse
      ? `/page/user-pulses/${userPulse}/`
      : can(fromJS(response), seeAdminDashboard())
      ? '/page/home'
      : '/page/dashboard';

    return makeActionChain(
      [() => setUiLanguage(loginLanguage)]
        .concat(
          loginLanguage !== savedUserLang
            ? [() => actions.updateUserLanguage(loginLanguage)]
            : []
        )
        .concat(
          response.consent_state !== CONSENT_STATES.AGREED
            ? [() => replace(getConsentUrl(userPulse))]
            : [
                () => userInfoCheckComplete({ payload: response }),
                () => push(url),
              ]
        )
    );
  };

  return makeActionChain(
    [() => actions.clearToken(), () => actions.login(email, password, activity)]
      .concat(activity ? [() => collections.getAllMyActivities()] : [])
      .concat([
        () => actions.getUserInfo(),
        response => processUserDetails(response),
      ])
  );
}

export function clearTokenExternalLoginAndRedirect(userId, path) {
  return makeActionChain([
    () => actions.clearToken(),
    () => actions.externalLogin(userId),
    () => actions.getUserInfo(),
    () => push(path),
  ]);
}

export const signUpAnonymousUserAndOpenActivity = (
  dateOfBirth,
  activityId
) => dispatch => {
  return (
    dispatch(actions.signUpAnonymousUser(dateOfBirth, activityId))
      .then(prevAction => {
        const { payload: user } = prevAction;
        return dispatch(actions.externalLogin(user.id));
      })
      .then(() => dispatch(actions.getUserInfo()))
      .then(() => dispatch(items.loadActivity(activityId)))
      .then(prevAction => {
        const { payload: activityData } = prevAction;
        const activity = fromJS(activityData);
        const productVersion = activity.getIn(['product_versions', 0]);
        dispatch(
          actions.startActivityProductVersionSession(
            activityId,
            productVersion.get('id')
          )
        );
        dispatch(
          modals.openRespondentAssessmentChecklistModal(
            productVersion,
            activityId
          )
        );
      })
      // eslint-disable-next-line no-console
      .catch(error => console.error(error))
  );
};

export function updateProfileOnModal(userId, profileData) {
  return makeActionChain([
    () => actions.updateProfile(userId, profileData),
    () => modals.openAdditionalDetailsThankYouModal(),
  ]);
}

export function cloneProductVersionAndCloseModal(productVersionId) {
  return makeActionChain([
    () => collections.cloneProductVersion(productVersionId),
    () => modals.closeTopModal(),
  ]);
}

export function addUserAndCloseModal(organisation, fullName, role, email) {
  return makeActionChain([
    () => collections.addUser(organisation, fullName, role, email),
    () => modals.closeTopModal(),
  ]);
}

export function addUserAndReset(organisationId, fullName, role, email) {
  return dispatch =>
    dispatch(collections.addUser(organisationId, fullName, role, email)).then(
      addUserResponse => {
        if (addUserResponse.meta.success) {
          dispatch(actions.sortSelection('users', 'full_name'));
        }
      }
    );
}

export function addUserAndSelect(organisationId, fullName, role, email) {
  return dispatch =>
    dispatch(collections.addUser(organisationId, fullName, role, email)).then(
      addUserResponse => {
        if (addUserResponse.meta.success) {
          dispatch(actions.sortSelection('users', 'full_name'));
          dispatch(
            actions.setSelection('users', addUserResponse.payload.id, true)
          );
        }
      }
    );
}

export function inviteUserAndCloseModal(id, email, opening, closing, langCode) {
  return makeActionChain([
    () => actions.inviteUser(id, email, opening, closing, langCode),
    () => modals.closeTopModal(),
  ]);
}

export function addOrganisationAndCloseModal(name, code, description) {
  return makeActionChain([
    () => collections.addOrganisation(name, code, description),
    () => modals.closeTopModal(),
  ]);
}

export function addCreditsAndCloseModal(
  id,
  amount,
  invoice,
  onPurchaseComplete
) {
  return dispatch =>
    dispatch(actions.addCredits(id, amount, invoice)).then(addCreditsAction => {
      if (addCreditsAction.meta.success) {
        return dispatch(modals.closeTopModal()).then(onPurchaseComplete);
      }
    });
}

export function addAccountCloseModalAndNavigateToEdit(
  name,
  description,
  accountType,
  hasUnlimitedCredits,
  accountSuperuserName,
  accountSuperuserEmail
) {
  return makeActionChain([
    () =>
      collections.addOrganisation(
        name,
        null,
        description,
        accountType,
        hasUnlimitedCredits,
        accountSuperuserName,
        accountSuperuserEmail
      ),
    org => push(`/page/accounts/${org.id}/`),
    () => modals.closeTopModal(),
  ]);
}

export function updateOrganisationAndCloseModal(id, organisation) {
  return makeActionChain([
    () => collections.updateOrganisation(id, organisation),
    () => modals.closeTopModal(),
  ]);
}

export function upgradeAccountTypeAndCloseModal(id, account_type) {
  return makeActionChain([
    () => collections.upgradeOrganisationType(id, account_type),
    () => modals.closeTopModal(),
  ]);
}

export function closeModalAndUpdateActivity(id, activity) {
  return makeActionChain([
    () => modals.closeTopModal(),
    () => collections.updateActivity(id, activity),
  ]);
}

export function addActivityAndStartEditing(newActivitySpec) {
  return makeActionChain([
    () => collections.addActivity(newActivitySpec),
    addedActivity => push('/page/activities/' + addedActivity.id),
    () => actions.getUserInfo(),
  ]);
}

export function showUsersAndImport() {
  return makeActionChain([
    () => push('/page/individuals/view'),
    () => modals.openImportUsersModal(),
  ]);
}

export function showUsersAndAdd() {
  return makeActionChain([
    () => push('/page/individuals/view'),
    () => modals.openCreateUserModal(),
  ]);
}

export function addAndInviteUser(
  organisation,
  fullName,
  role,
  email,
  opening,
  closing,
  langCode
) {
  return makeActionChain([
    () => collections.addUser(organisation, fullName, role, email),
    addedUser =>
      actions.inviteUser(addedUser.id, email, opening, closing, langCode),
    () => setInputTextLanguage(DEFAULT_LANGUAGE_CODE),
    () => modals.closeTopModal(),
  ]);
}

export function generateProfile(activityId, profileId) {
  return makeActionChain([
    () => actions.generateSuccessProfile(profileId),
    () => items.loadActivity(activityId),
    () => modals.closeTopModal(),
  ]);
}

export function verifyProfile(activityId, profileId, data) {
  return makeActionChain([
    () => actions.verifySuccessProfile(profileId, data),
    () => items.loadActivity(activityId),
    () => modals.closeTopModal(),
  ]);
}

export function addRaterUserThen(
  nextAction,
  email,
  fullName,
  external = false,
  source = false,
  external_profile = false,
  organisationId
) {
  return makeActionChain([
    () =>
      actions.addRaterUser(
        email,
        fullName,
        external,
        source,
        external_profile,
        organisationId
      ),
    nextAction,
  ]);
}

export function pollProgressAndUpdate(importId, key) {
  return makeActionChain([
    () => actions.pollProgress(importId),
    importProgress =>
      actions.updateProgress(key, importProgress.value, importId),
  ]);
}

export function importUsersAndPollProgress(organisation, data, key, groupId) {
  return makeActionChain([
    () => actions.importUsers(organisation, data, groupId),
    importData => pollProgressAndUpdate(importData.id, key),
  ]);
}

export function saveField(onSave, id, value) {
  return dispatch =>
    Promise.resolve(onSave(value)).then(response => {
      if (response && response.error) {
        throw new Error('Could not save');
      }
      dispatch(actions.stopEditingField(id));
    });
}

export function saveISPAnswersAndShowNext(
  activity,
  productVersion,
  raterFor,
  _language,
  questionGroup,
  answers,
  reloadAnswers = false
) {
  return makeActionChain(
    _.filter([
      answers.count() &&
        (() =>
          actions.saveISPAnswers(
            activity.get('id'),
            productVersion.get('id'),
            raterFor && raterFor.get('id'),
            questionGroup,
            answers
          )),
      reloadAnswers &&
        (() =>
          actions.getMyAnswers(activity.get('id'), productVersion.get('id'))),
      actions.showNextProductVersionPage,
      () => actions.scrollTop('.modal-overlay'),
    ])
  );
}

export function saveRankingAnswersAndShowNext(
  activity,
  productVersion,
  _language,
  questionGroup,
  answers
) {
  return makeActionChain(
    _.filter([
      answers.count() &&
        (() =>
          actions.saveRankingAnswers(
            activity.get('id'),
            productVersion.get('id'),
            questionGroup,
            answers
          )),
      actions.showNextProductVersionPage,
      () => actions.scrollTop('.modal-overlay'),
    ])
  );
}

export function saveSJTAnswersAndShowNext(
  activity,
  productVersion,
  _language,
  orderedQuestion,
  answers
) {
  return makeActionChain(
    _.filter([
      answers.count() &&
        (() =>
          actions.saveSJTAnswers(
            activity.get('id'),
            productVersion.get('id'),
            orderedQuestion,
            answers
          )),
      actions.showNextProductVersionPage,
      () => actions.scrollTop('.modal-overlay'),
    ])
  );
}

export function cycleToCorrectModalPage(productVersion, addBreakPage) {
  const productType =
    productVersion && productVersion.getIn(['product', 'type']);
  const productHasAdditionalWelcomeScreens = getAdditionalWelcomeScreens()[
    productType
  ];
  if (!productVersion && !addBreakPage) {
    return actions.setWelcomeModalPage(0);
  }

  if (addBreakPage) {
    return actions.setWelcomeModalPage(4);
  }

  if (
    productVersion.get('welcome_message_short') ||
    productVersion.get('welcome_message_long')
  ) {
    return actions.setWelcomeModalPage(0);
  }

  if (productHasAdditionalWelcomeScreens) {
    return actions.setWelcomeModalPage(1);
  }

  if (productVersion.get('instructions', null)) {
    return actions.setWelcomeModalPage(2);
  }

  if (productVersion.get('confirmation_text')) {
    return actions.setWelcomeModalPage(3);
  }

  return null;
}

export function clearPreviousAndLoadNewActivity(activityId) {
  return makeActionChain([
    () => items.clearItem('activities'),
    () => items.loadActivity(activityId),
  ]);
}

export function loadDataAndOpenWelcomeModal(
  productVersion,
  activityId,
  raterFor,
  language,
  questionCollectionIdx = 0
) {
  return makeActionChain([
    () => loadAndSetActiveLanguage(language),
    () => items.clearItem('activities'),
    () => items.loadActivity(activityId),
    activity => {
      const productVersionDetails =
        activity &&
        fromJS(activity)
          .get('product_versions', List())
          .find(
            fullProductVersion =>
              fullProductVersion.get('id') === productVersion.get('id')
          );
      const setCorrectWelcomeModalPage = cycleToCorrectModalPage(
        productVersionDetails,
        false
      );
      if (setCorrectWelcomeModalPage) {
        return makeActionChain([
          () => setCorrectWelcomeModalPage,
          () => actions.resetAdditionalWelcomeModalPage(),
          () =>
            modals.openWelcomeModal(productVersionDetails, activity, raterFor),
        ]);
      }
      return startProductVersion(
        productVersionDetails,
        activity,
        raterFor,
        questionCollectionIdx
      );
    },
  ]);
}

export const setActiveLanguageAndOpenAssessmentModal = (
  language,
  productVersion,
  activityId,
  raterFor,
  questionCollectionIdx
) => {
  return makeActionChain([
    () => loadAndSetActiveLanguage(language),
    () => actions.getActivityDetail(activityId),
    activityDetail => {
      const fetchedProductVersion = activityDetail.product_versions.find(
        pv => pv.id === productVersion.get('id')
      );
      return modals.openRespondentAssessmentChecklistModal(
        fromJS(fetchedProductVersion),
        activityId,
        raterFor,
        questionCollectionIdx
      );
    },
  ]);
};

export function setCorrectWelcomeModalPageAndOpenPerspectivesWelcomeModal(
  productVersionDetails,
  activity,
  raterFor,
  questionCollectionIdx = 0
) {
  const setCorrectWelcomeModalPage = cycleToCorrectModalPage(
    productVersionDetails,
    false
  );
  const openModal = modals.openPerspectivesWelcomeModal;

  const questionCollection = productVersionDetails.getIn([
    'questioncollection_set',
    questionCollectionIdx,
  ]);
  if (setCorrectWelcomeModalPage) {
    return makeActionChain([
      () => setCorrectWelcomeModalPage,
      () => actions.resetAdditionalWelcomeModalPage(),
      () =>
        openModal(
          productVersionDetails,
          activity,
          raterFor,
          questionCollection
        ),
    ]);
  }
  return openModal(
    productVersionDetails,
    activity,
    raterFor,
    questionCollection
  );
}

export function setCorrectWelcomeModalPageAndOpenPsycapPotentialWelcomeModal(
  productVersionDetails,
  activity,
  raterFor,
  questionCollectionIdx = 0
) {
  const setCorrectWelcomeModalPage = cycleToCorrectModalPage(
    productVersionDetails,
    false
  );
  const openModal = activity.get('is_staged')
    ? modals.openWelcomeModal
    : modals.openPsycapPotentialWelcomeModal;
  const questionCollection = productVersionDetails.getIn([
    'questioncollection_set',
    questionCollectionIdx,
  ]);
  if (setCorrectWelcomeModalPage) {
    return makeActionChain([
      () => setCorrectWelcomeModalPage,
      () => actions.resetAdditionalWelcomeModalPage(),
      () =>
        openModal(
          productVersionDetails,
          activity,
          raterFor,
          questionCollection
        ),
    ]);
  }
  return openModal(
    productVersionDetails,
    activity,
    raterFor,
    questionCollection
  );
}

export function setCorrectWelcomeModalPageAndOpenWelcomeModalAndStartProductVersion(
  productVersionDetails,
  activity,
  raterFor,
  questionCollectionIdx = 0
) {
  const setCorrectWelcomeModalPage = cycleToCorrectModalPage(
    productVersionDetails,
    false
  );
  if (setCorrectWelcomeModalPage) {
    return makeActionChain([
      () => setCorrectWelcomeModalPage,
      () => actions.resetAdditionalWelcomeModalPage(),
      () => modals.openWelcomeModal(productVersionDetails, activity, raterFor),
    ]);
  }
  return startProductVersion(
    productVersionDetails,
    activity,
    raterFor,
    questionCollectionIdx
  );
}

export function maybeOpenWelcomeModalAndOpenProductVersion(
  productVersion,
  activity,
  raterFor,
  questionCollectionIdx,
  relaunch
) {
  return makeActionChain(
    _.filter([
      actions.offBeforeUnload,
      () => collections.getAllMyActivities(),
      () => {
        const productVersionDetails =
          activity &&
          fromJS(activity)
            .get('product_versions', List())
            .find(
              fullProductVersion =>
                fullProductVersion.get('id') === productVersion.get('id')
            );
        const questionCollection = productVersionDetails.getIn([
          'questioncollection_set',
          questionCollectionIdx,
        ]);

        const isPerspectives = Boolean(
          productVersion.getIn(['product', 'type']) ===
            PRODUCT_TYPES.CHOICES.PERSPECTIVES
        );
        const qIndexCount = productVersion
          .get('questioncollection_set', List())
          .count();

        const setCorrectWelcomeModalPage = cycleToCorrectModalPage(
          questionCollection,
          Boolean(
            questionCollectionIdx > 0 &&
              questionCollectionIdx <= qIndexCount &&
              isPerspectives &&
              !relaunch
          )
        );

        if (setCorrectWelcomeModalPage) {
          return makeActionChain([
            () => setCorrectWelcomeModalPage,
            () => actions.resetAdditionalWelcomeModalPage(),
            () =>
              modals.openWelcomeModal(
                productVersion,
                activity,
                raterFor,
                questionCollection
              ),
          ]);
        }
        return openProductVersion(
          productVersion,
          activity,
          raterFor,
          questionCollectionIdx
        );
      },
    ])
  );
}

function saveAndSubmit(
  saveFunction,
  activity,
  productVersion,
  raterFor,
  language,
  questionCollectionIdx = 0,
  timeoutText = null,
  reloadAnswers = false
) {
  const isPerspectivesProduct =
    productVersion.getIn(['product', 'type']) ===
    PRODUCT_TYPES.CHOICES.PERSPECTIVES;
  const perspectivesTimeoutText = i18next.t(
    'Please wait while your answers are being submitted.'
  );
  const isMapQuestionCollection =
    productVersion.getIn([
      'questioncollection_set',
      questionCollectionIdx,
      'question_type',
    ]) === PRODUCT_TYPES.CHOICES.MAP;
  const nextQuestionCollectionIdx = questionCollectionIdx + 1;
  const isLastQuestionCollection =
    nextQuestionCollectionIdx >=
    productVersion.get('questioncollection_set', List()).count();
  const isTimeToStop = activity.get('is_staged') || isLastQuestionCollection;
  const onContinue = isTimeToStop
    ? () => resetLanguageAndCloseAllModals(language)
    : () =>
        maybeOpenWelcomeModalAndOpenProductVersion(
          productVersion,
          activity,
          raterFor,
          nextQuestionCollectionIdx,
          false
        );
  return makeActionChain(
    _.filter([
      () =>
        modals.openCompletedProductVersionModal(
          activity,
          productVersion,
          raterFor,
          isPerspectivesProduct && !isLastQuestionCollection
            ? perspectivesTimeoutText
            : timeoutText,
          onContinue,
          isTimeToStop
        ),
      actions.offBeforeUnload,
      () => collections.getAllMyActivities(),
      saveFunction,
      reloadAnswers &&
        (() =>
          actions.getMyAnswers(activity.get('id'), productVersion.get('id'))),
      () =>
        actions.updateActivityProductVersionSession(
          activity.get('id'),
          productVersion.get('id'),
          raterFor && raterFor.get('id'),
          {
            ended: isLastQuestionCollection,
            map_ended: isMapQuestionCollection,
            can_resume: false,
          }
        ),
      actions.offBeforeUnload,
      () => collections.getAllMyActivities(),
    ])
  );
}

export function saveISPAnswersAndSubmit(
  activity,
  productVersion,
  raterFor,
  language,
  questionGroup,
  answers,
  questionCollectionIdx
) {
  return saveAndSubmit(
    answers.count() &&
      (() =>
        actions.saveISPAnswers(
          activity.get('id'),
          productVersion.get('id'),
          raterFor && raterFor.get('id'),
          questionGroup,
          answers
        )),
    activity,
    productVersion,
    raterFor,
    language,
    questionCollectionIdx
  );
}

export function saveRankingAnswersAndSubmit(
  activity,
  productVersion,
  language,
  questionGroup,
  answers,
  questionCollectionIdx
) {
  return saveAndSubmit(
    answers.count() &&
      (() =>
        actions.saveRankingAnswers(
          activity.get('id'),
          productVersion.get('id'),
          questionGroup,
          answers
        )),
    activity,
    productVersion,
    null,
    language,
    questionCollectionIdx
  );
}

export function saveRankingSuccessAnswersAndSubmit(
  activity,
  productVersion,
  language,
  questionGroup,
  answers,
  questionCollectionIdx
) {
  return saveAndSubmit(
    answers.count() &&
      (() =>
        actions.saveRankingSuccessAnswers(
          activity.get('id'),
          productVersion.get('id'),
          questionGroup,
          answers
        )),
    activity,
    productVersion,
    null,
    language,
    questionCollectionIdx,
    null,
    true
  );
}

export function saveSJTAnswersAndSubmit(
  activity,
  productVersion,
  language,
  orderedQuestion,
  answers,
  questionCollectionIdx
) {
  return saveAndSubmit(
    answers.count() &&
      (() =>
        actions.saveSJTAnswers(
          activity.get('id'),
          productVersion.get('id'),
          orderedQuestion,
          answers
        )),
    activity,
    productVersion,
    null,
    language,
    questionCollectionIdx
  );
}

export function markMAPCompleteAndFinish(
  activity,
  productVersion,
  questionCollectionIdx,
  language,
  timeoutText = null
) {
  return saveAndSubmit(
    null,
    activity,
    productVersion,
    null,
    language,
    questionCollectionIdx,
    timeoutText
  );
}

export function goToMultiLikertQuestionPrevious(
  activity,
  productVersion,
  answers,
  previousPage
) {
  return makeActionChain([
    () => actions.setProductVersionPage(previousPage),
    () => actions.scrollTop('.modal-overlay'),
  ]);
}

export function goToMultiLikertQuestionNext(
  activity,
  productVersion,
  answers,
  nextPage
) {
  return makeActionChain([
    () => actions.setProductVersionPage(nextPage),
    () => actions.scrollTop('.modal-overlay'),
  ]);
}

export function answerMultipleQuestionsAndAdvance(
  activity,
  productVersion,
  raterFor,
  answers,
  nextPage
) {
  return makeActionChain(
    _.filter([
      answers.count() &&
        (() =>
          actions.answerMultipleQuestions(
            activity.get('id'),
            productVersion.get('id'),
            raterFor && raterFor.get('id'),
            answers
          )),
      () => actions.setProductVersionPage(nextPage),
      () => actions.scrollTop('.modal-overlay'),
    ])
  );
}

export function answerMultiLikertQuestionAndAdvance(
  activity,
  productVersion,
  answers,
  nextPage
) {
  return makeActionChain([
    () =>
      actions.answerMultiLikertQuestion(
        activity.get('id'),
        productVersion.get('id'),
        answers
      ),
    () => actions.setProductVersionPage(nextPage),
    () => actions.clearAnswers(),
    () =>
      actions.getMyMultiLikertAnswers(
        activity.get('id'),
        productVersion.get('id'),
        null
      ),
    () => actions.scrollTop('.modal-overlay'),
  ]);
}

export function answerMultiLikertQuestionAndSubmit(
  activity,
  productVersion,
  answers,
  questionCollectionIdx,
  language
) {
  return saveAndSubmit(
    () =>
      actions.answerMultiLikertQuestion(
        activity.get('id'),
        productVersion.get('id'),
        answers
      ),
    activity,
    productVersion,
    null,
    language,
    questionCollectionIdx
  );
}

export function answerMultiLikertQuestionAndOnlySubmit(
  activity,
  productVersion,
  answers,
  questionCollectionIdx,
  language
) {
  return saveAndSubmit(
    null,
    activity,
    productVersion,
    null,
    language,
    questionCollectionIdx
  );
}

export function answerMultiLikertQuestionAndClose(
  activity,
  productVersion,
  answers,
  _questionCollectionIdx,
  language
) {
  if (answers && answers.count() > 1) {
    return makeActionChain([
      () =>
        actions.answerMultiLikertQuestion(
          activity.get('id'),
          productVersion.get('id'),
          answers
        ),
      actions.offBeforeUnload,
      () => resetLanguageAndCloseTopModal(language),
      () => collections.getAllMyActivities(),
    ]);
  }
  return makeActionChain([
    actions.offBeforeUnload,
    () => resetLanguageAndCloseTopModal(language),
    () => collections.getAllMyActivities(),
  ]);
}

export function answerMultipleQuestionsAndSubmit(
  activity,
  productVersion,
  raterFor,
  answers,
  nextPage,
  questionCollectionIdx,
  language
) {
  return saveAndSubmit(
    answers.count() &&
      (() =>
        actions.answerMultipleQuestions(
          activity.get('id'),
          productVersion.get('id'),
          raterFor && raterFor.get('id'),
          answers
        )),
    activity,
    productVersion,
    raterFor,
    language,
    questionCollectionIdx
  );
}

export function answerMultipleQuestionsSaveRankingsAndSubmit(
  activity,
  productVersion,
  raterFor,
  answers,
  nextPage,
  questionCollectionIdx,
  language,
  id
) {
  return makeActionChain(
    _.filter([
      answers.count() &&
        (() =>
          actions.answerMultipleQuestions(
            activity.get('id'),
            productVersion.get('id'),
            raterFor && raterFor.get('id'),
            answers
          )),
      () => items.loadActivity(id),
      activityDetail => {
        return actions.savePerspectivesLikert(
          activityDetail.product_versions
            .filter(item => item.id === productVersion.get('id'))
            .flatMap(i => i.activity_product_version_sessions)[0].id
        );
      },
      () =>
        actions.updateActivityProductVersionSession(
          activity.get('id'),
          productVersion.get('id'),
          raterFor && raterFor.get('id'),
          {
            ended: false,
            map_ended: false,
            can_resume: false,
          }
        ),
      () => actions.setProductVersionPage(0),
      actions.offBeforeUnload,
      () => collections.getAllMyActivities(),
      () =>
        openProductVersion(
          productVersion,
          activity,
          raterFor,
          questionCollectionIdx,
          0,
          true
        ),
    ])
  );
}

export function answerMultipleQuestionsAndClose(
  activity,
  productVersion,
  raterFor,
  answers,
  _destinationPage,
  questionCollectionIdx,
  languageToRestore
) {
  if (answers && !answers.isEmpty()) {
    return makeActionChain([
      () =>
        actions.answerMultipleQuestions(
          activity.get('id'),
          productVersion.get('id'),
          raterFor && raterFor.get('id'),
          answers
        ),
      actions.offBeforeUnload,
      () => collections.getAllMyActivities(),
      () => items.loadActivity(activity.get('id')),
      () => loadAndSetActiveLanguage(languageToRestore),
      () => modals.closeTopModal(),
    ]);
  }
  return makeActionChain([
    actions.offBeforeUnload,
    () => collections.getAllMyActivities(),
    () => items.loadActivity(activity.get('id')),
    () => loadAndSetActiveLanguage(languageToRestore),
    () => modals.closeTopModal(),
  ]);
}

const getIndexOfFirstPartiallyCompleteQuestionCollection = (
  answers,
  session,
  questionCollections,
  isSuccessProfile
) => {
  const answeredQuestions = answers.map(each => each.get('question')).toSet();
  const answeredGroupedQuestions = answers.reduce((acc, each) => {
    const groupedQuestion = each.get('grouped_question');
    if (groupedQuestion) {
      return acc.add(groupedQuestion);
    }
    return acc;
  }, Set());

  const mapSession = session.get('map_activity_product_version_session');
  const notComplete = (questionCollection, idx) => {
    const questions = questionCollection.get('questions');
    const questionGroups = questionCollection.get('question_groups');
    const isMap =
      questionCollection.get('question_type') === PRODUCT_TYPES.CHOICES.MAP;

    const totalGroupedQuestionNumber = fromJS(
      questionGroups.map(item => item.get('questions').size)
    ).reduce((prev, current) => prev + current);
    const isComplete = isMap
      ? mapSession && mapSession.get('completed')
      : questions.every(question =>
          answeredQuestions.includes(question.get('id'))
        ) &&
        ((!isSuccessProfile && !totalGroupedQuestionNumber) ||
          (answeredGroupedQuestions.size > 1 &&
            totalGroupedQuestionNumber === answeredGroupedQuestions.size) ||
          (isSuccessProfile &&
            idx < PROFILE_MOTIVATORS_INDEX &&
            answeredGroupedQuestions.size >= totalGroupedQuestionNumber));
    return !isComplete;
  };

  return questionCollections.findIndex(notComplete);
};

function hasIncompleteCompetenciesGroupedQuestionsSection(
  productVersion,
  answers,
  session,
  compQuestionGroups
) {
  if (!session) {
    return false;
  }
  // Don't have any competencies grouped questions
  if (compQuestionGroups.length === 0) {
    return false;
  }
  // Haven't completed competencies likert questions
  const questionCollections = productVersion.get(
    'questioncollection_set',
    List()
  );
  const firstPartiallyCompleteIdx = getIndexOfFirstPartiallyCompleteQuestionCollection(
    answers,
    session,
    questionCollections
  );
  if (firstPartiallyCompleteIdx <= PERSPECTIVES_COMPETENCIES_COLLECTION_INDEX) {
    return false;
  }

  // Have answered all competencies grouped questions
  const compQuestions = compQuestionGroups.flatMap(
    ({ questions }) => questions
  );

  const compGroupAnswersCount = answers
    .filter(item => item.get('competencies_grouped_question') !== null)
    .count();
  if (compGroupAnswersCount === compQuestions.length) {
    return false;
  }

  return true;
}

function findCompGroupQuestionsPage(
  productVersion,
  answers,
  compQuestionGroups
) {
  const compGroupAnswersCount = answers
    .filter(item => item.get('competencies_grouped_question') !== null)
    .count();

  const questionCollections = productVersion.get(
    'questioncollection_set',
    List()
  );

  if (compQuestionGroups.length === 0) {
    return 0;
  }

  const compQuestions = compQuestionGroups.flatMap(
    ({ questions }) => questions
  );

  if (compGroupAnswersCount === compQuestions.length) {
    throw new Error('All competencies grouped questions are answered');
  }

  const nextCompQuestionGroupId =
    compQuestions[compGroupAnswersCount].question_group;

  const nonEmptyQuestionGroups = compQuestionGroups.filter(
    item => item.questions.length
  );
  const nextGroupIndex = nonEmptyQuestionGroups.findIndex(
    group => group.id === nextCompQuestionGroupId
  );

  return nextGroupIndex;
}

function computeResumeQuestionCollectionIdx(
  productVersion,
  answers,
  session,
  compQuestionGroups,
  isSuccessProfile
) {
  if (!session) {
    return 0;
  }

  const compGroupAnswersCount = answers
    .filter(item => item.get('competencies_grouped_question') !== null)
    .count();

  const questionCollections = productVersion.get(
    'questioncollection_set',
    List()
  );

  const firstPartiallyCompleteIdx = getIndexOfFirstPartiallyCompleteQuestionCollection(
    answers,
    session,
    questionCollections,
    isSuccessProfile
  );

  if (firstPartiallyCompleteIdx === -1) {
    return 0;
  }
  const isPerspectives =
    productVersion.getIn(['product', 'type']) ===
    PRODUCT_TYPES.CHOICES.PERSPECTIVES;
  const incompleteCompetenciesRankedQuestions =
    isPerspectives &&
    firstPartiallyCompleteIdx > PERSPECTIVES_COMPETENCIES_COLLECTION_INDEX &&
    compQuestionGroups.length > 0 &&
    compGroupAnswersCount !== PERSPECTIVES_COMPETENCIES_NUMBER_OF_QUESTIONS;
  return incompleteCompetenciesRankedQuestions
    ? PERSPECTIVES_COMPETENCIES_COLLECTION_INDEX
    : firstPartiallyCompleteIdx;
}

function getCurrentMAPQuestionIdx(session, questionCollection) {
  if (!session) {
    return 0;
  }
  const currentQuestionId = session.getIn([
    'map_activity_product_version_session',
    'last_question_changed',
  ]);
  const questions = questionCollection.get('image_match_questions');
  const currentQuestionIdx = questions.findIndex(
    question => question.get('id') === currentQuestionId
  );
  return currentQuestionIdx > -1 ? currentQuestionIdx : 0;
}

function getAreQuestionsStarted(questionCollection, answers) {
  const answeredQuestions = answers.map(each => each.get('question')).toSet();
  const questionGroups = questionCollection.get('question_groups');
  const answeredGroupedQuestions = answers
    .map(each => each.get('grouped_question'))
    .toSet();

  const totalGroupedQuestionNumber = fromJS(
    questionGroups.map(item => item.get('questions').size)
  ).reduce((prev, current) => prev + current);

  const isAnswered = question => answeredQuestions.includes(question.get('id'));
  const questions = questionCollection.get('questions');
  const isStarted =
    questions.some(isAnswered) ||
    (answeredGroupedQuestions &&
      answeredGroupedQuestions.size > 1 &&
      answeredGroupedQuestions.size < totalGroupedQuestionNumber);
  return isStarted;
}

export function continueProductVersion(
  productVersion,
  activity,
  raterFor,
  activeLanguage
) {
  const { id: productVersionId, product } = productVersion.toObject();
  const { id: activityId } = activity.toObject();
  const { type, can_resume: productCanResume } = product.toObject();
  const actionToCall =
    type === PRODUCT_TYPES.CHOICES.DISC
      ? actions.getMyMultiLikertAnswers
      : actions.getMyImageMatchAnswers;
  return dispatch => {
    dispatch(actions.clearAnswers());
    return dispatch(
      actions.getMyAnswers(
        activityId,
        productVersionId,
        raterFor && raterFor.get('id')
      )
    )
      .then(answersResponseAction => {
        if (!answersResponseAction.meta.success) {
          throw new Error('Could not get answers');
        }
        const answers = fromJS(answersResponseAction.payload);
        return dispatch(
          actionToCall(
            activityId,
            productVersionId,
            raterFor && raterFor.get('id')
          )
        ).then(secondaryAnswersResponseAction => ({
          secondaryAnswersResponseAction,
          answers,
        }));
      })
      .then(({ secondaryAnswersResponseAction, answers }) => {
        if (!secondaryAnswersResponseAction.meta.success) {
          throw new Error('Could not get image match answers');
        }
        return dispatch(
          items.loadActivity(activityId)
        ).then(activityResponseAction => ({ activityResponseAction, answers }));
      })
      .then(({ activityResponseAction, answers }) => {
        if (activityResponseAction.meta.success) {
          const activityDetails = fromJS(activityResponseAction.payload);
          const productVersionDetails = activityDetails
            .get('product_versions')
            .find(each => each.get('id') === productVersionId);
          const productVersionSession = productVersionDetails
            .get('activity_product_version_sessions', List())
            .find(
              each =>
                each.get('activity') === activityId &&
                (!raterFor || each.get('rater_for') === raterFor.get('id'))
            );
          const isPerspectives = Boolean(
            productVersion.getIn(['product', 'type']) ===
              PRODUCT_TYPES.CHOICES.PERSPECTIVES
          );
          const isSuccessProfile = Boolean(
            productVersion.getIn(['product', 'name']) ===
              PRODUCT_ACTIVITY_TYPES.DISPLAY.PERSPECTIVES_SUCCESS_PROFILE
          );

          const sessionId = productVersionDetails.getIn([
            'activity_product_version_sessions',
            0,
            'id',
          ]);
          dispatch(actions.rankedQuestions(sessionId)).then(prevAction => {
            const compQuestionGroups =
              prevAction.type ===
              actions.GET_COMPETENCIES_GROUPED_QUESTIONS.SUCCESS
                ? prevAction.payload
                : [];

            const resumeQuestionCollectionIdx = computeResumeQuestionCollectionIdx(
              productVersionDetails,
              answers,
              productVersionSession,
              compQuestionGroups,
              isSuccessProfile
            );
            const isCompGroupedQuestionSection = isPerspectives
              ? hasIncompleteCompetenciesGroupedQuestionsSection(
                  productVersionDetails,
                  answers,
                  productVersionSession,
                  compQuestionGroups
                )
              : false;

            const compGroupQuestionsPageNumber =
              isPerspectives && isCompGroupedQuestionSection
                ? findCompGroupQuestionsPage(
                    productVersionDetails,
                    answers,
                    compQuestionGroups
                  )
                : 0;
            const questionCollection = productVersionDetails.getIn([
              'questioncollection_set',
              resumeQuestionCollectionIdx,
            ]);

            const isMapCollection =
              questionCollection.get('question_type') ===
              PRODUCT_TYPES.CHOICES.MAP;
            let currentMAPQuestionIdx = 0;
            if (isMapCollection) {
              currentMAPQuestionIdx = getCurrentMAPQuestionIdx(
                productVersionSession,
                questionCollection
              );
              const currentMAPQuestion = questionCollection
                .get('image_match_questions', List())
                .get(currentMAPQuestionIdx);
              if (!currentMAPQuestion.get('is_practice')) {
                dispatch(actions.startMapProductVersionTimer());
              }
            }

            if (productCanResume) {
              dispatch(actions.setActivityProductVersionSessionCanResume(true));
            } else {
              // If the product doesn't always allow resuming, take away the user's ability to resume
              dispatch(
                actions.updateActivityProductVersionSession(
                  activityId,
                  productVersionId,
                  raterFor && raterFor.get('id'),
                  { can_resume: false }
                )
              );
              // Update progress state to reflect remote changes
              dispatch(
                actions.setActivityProductVersionSessionCanResume(false)
              );
            }

            dispatch(
              loadAndSetActiveLanguage(
                getLangCodeWithFallback(
                  activeLanguage,
                  productVersionDetails.get('available_languages').toJS()
                )
              )
            );

            if (
              isPerspectives &&
              !isMapCollection &&
              !getAreQuestionsStarted(questionCollection, answers)
            ) {
              dispatch(
                maybeOpenWelcomeModalAndOpenProductVersion(
                  productVersionDetails,
                  activityDetails,
                  raterFor,
                  resumeQuestionCollectionIdx,
                  true
                )
              );
            } else if (isCompGroupedQuestionSection) {
              dispatch(
                actions.setProductVersionPage(compGroupQuestionsPageNumber)
              ).then(
                dispatch(
                  openProductVersion(
                    productVersionDetails,
                    activityDetails,
                    raterFor,
                    resumeQuestionCollectionIdx,
                    currentMAPQuestionIdx,
                    isCompGroupedQuestionSection
                  )
                )
              );
            } else {
              dispatch(
                openProductVersion(
                  productVersionDetails,
                  activityDetails,
                  raterFor,
                  resumeQuestionCollectionIdx,
                  currentMAPQuestionIdx,
                  isCompGroupedQuestionSection
                )
              );
            }
          });
        }
      });
  };
}

export function removeUserFromActivityAndRefresh(activityId, userId) {
  return makeActionChain([
    () => actions.removeUserFromActivity(activityId, userId),
    () => collections.getActivityUsers(activityId),
    () => items.loadActivity(activityId),
  ]);
}

export function addUsersToActivityAndRefresh(activityId, userIds) {
  return makeActionChain([
    () => actions.addUsersToActivity(activityId, userIds),
    () => collections.getActivityUsers(activityId),
    () => items.loadActivity(activityId),
    () => items.loadActivityRespondents(activityId),
  ]);
}

export function addProfileCreatorToActivityAndRefresh(
  activityId,
  userIds,
  newUserId
) {
  return makeActionChain([
    () => actions.removeUsersFromActivity(activityId, userIds),
    () => actions.addUsersToActivity(activityId, newUserId),
    () => collections.getActivityUsers(activityId),
    () => items.loadActivity(activityId),
    () => items.loadActivityRespondents(activityId),
  ]);
}
export function addUsersToGroupAndRefresh(groupId, userIds) {
  return makeActionChain([
    () => actions.addUsersToGroup(groupId, userIds),
    () => items.loadGroup(groupId),
  ]);
}

export function addRemoveUsersGroupAndRefresh(groupId, userIds) {
  return makeActionChain([
    () => actions.addRemoveUsersGroup(groupId, userIds),
    () => items.loadGroup(groupId),
    () => modals.closeTopModal(),
  ]);
}

export function addGroupsToActivityAndRefresh(activityId, groupIds) {
  return makeActionChain([
    () => actions.addGroupsToActivity(activityId, groupIds),
    () => items.loadActivity(activityId),
    () => items.loadActivityRespondents(activityId),
    () => collections.getActivityUsers(activityId),
  ]);
}

export function updateReportNotificationEmailAndClose(
  activityId,
  notificationId,
  values
) {
  return makeActionChain([
    () =>
      actions.updateReportNotificationEmail(activityId, notificationId, values),
    () => modals.closeTopModal(),
  ]);
}

export function createReportNotificationEmailAndClose(activityId, values) {
  return makeActionChain([
    () => actions.createReportNotificationEmail(activityId, values),
    () => modals.closeTopModal(),
  ]);
}

export function updateNotificationEmailAndClose(
  activityId,
  notificationId,
  values
) {
  return makeActionChain([
    () => actions.updateNotificationEmail(activityId, notificationId, values),
    () => modals.closeTopModal(),
  ]);
}

export function createNotificationEmailAndClose(activityId, values) {
  return makeActionChain([
    () => actions.createNotificationEmail(activityId, values),
    () => modals.closeTopModal(),
  ]);
}

export function deleteNotificationEmailAndClose(
  activityId,
  notificationId,
  type
) {
  return makeActionChain([
    () => actions.deleteNotificationEmail(activityId, notificationId),
    () => actions.stopEditingField(type),
    () => items.loadActivity(activityId),
  ]);
}

export function sudoLoginCloseModalAndGoHome(email, password) {
  return makeActionChain(
    _.filter([
      () => actions.sudoLogin(email, password),
      () => modals.closeTopModal(),
      () => push('/page/refresh'),
      getAndCheckUserInfo,
    ])
  );
}

export function sudoLogoutAndGoHome() {
  return makeActionChain(
    _.filter([
      () => modals.closeTopModal(),
      actions.sudoLogout,
      () => push('/page/refresh'),
    ])
  );
}

export function resendEmailAndShowModal(activityId, type) {
  return dispatch => {
    return dispatch(actions.resendNotificationEmail(activityId, type)).then(
      responseAction => {
        if (responseAction.type === actions.RESEND_NOTIFICATION_EMAIL.SUCCESS) {
          return dispatch(
            modals.resendNotificationCompleteModal(
              actions.RESEND_NOTIFICATION_EMAIL.SUCCESS
            )
          );
        }
        return dispatch(
          modals.resendNotificationCompleteModal(
            actions.RESEND_NOTIFICATION_EMAIL.FAILURE
          )
        );
      }
    );
  };
}

export function updateUserStatusAndCloseModal(id, status) {
  return makeActionChain([
    () => collections.updateUserStatus(id, status),
    () => modals.closeTopModal(),
  ]);
}

export function getTokenAndOpenTokenExpiredModal(exportType, metadata) {
  return makeActionChain([
    () => actions.getToken(),
    () => modals.openTokenExpiredModal(exportType, metadata),
  ]);
}

// Due to URL length restrictions only do a direct link for a small number of reports
const MAX_INLINE_REPORTS = 50;

export function tryViewExport(token, exportType, metadata) {
  const _tryViewExport = data =>
    token.get('expires').isAfter(moment())
      ? actions.viewExport(token.get('token'), exportType, data)
      : getTokenAndOpenTokenExpiredModal(exportType, data);

  const urlTooLong =
    exportType === 'DOWNLOAD_REPORTS' &&
    metadata.reports &&
    metadata.reports.length > MAX_INLINE_REPORTS;

  if (urlTooLong) {
    return makeActionChain([
      () => actions.createReportDownload(metadata.reports),
      reportDownloadId =>
        _tryViewExport({ report_download_batch: reportDownloadId }),
    ]);
  }
  return makeActionChain([() => _tryViewExport(metadata)]);
}

export function closeModalAndTryViewExport(token, exportType, metadata) {
  return makeActionChain([
    () => modals.closeTopModal(),
    () => tryViewExport(token, exportType, metadata),
  ]);
}

export function setFormStageAndTryViewExport(
  token,
  exportType,
  metadata,
  formName,
  formStage
) {
  return makeActionChain([
    () => actions.setFormStage(formName, formStage),
    () => tryViewExport(token, exportType, metadata),
  ]);
}

export function closeModalAndSetFormStage(formName, formStage) {
  return makeActionChain([
    () => modals.closeTopModal(),
    () => actions.setFormStage(formName, formStage),
  ]);
}

export function setFormStageAndShowGenerationProgress() {
  return makeActionChain([
    () => actions.setFormStage('reportGenerationFlow', 'GENERATING'),
    () => modals.openReportGenerationModal(),
  ]);
}

export function deleteActivityCloseModalAndNavigateTo(activityId, path) {
  return makeActionChain([
    () => collections.deleteActivity(activityId),
    () => actions.getUserInfo(),
    () => modals.closeTopModal(),
    () => push(path),
  ]);
}

export function setLineManagerAndGetMyActivities(activityId, lineManagerId) {
  return makeActionChain([
    () => actions.setLineManager(activityId, lineManagerId),
    () => collections.getAllMyActivities(),
  ]);
}

export function submitRatersThenNavigateTo(activityId, path) {
  return makeActionChain([
    () => actions.submitRaters(activityId),
    () => push(path),
  ]);
}

export function setMAPProductVersionQuestionIndexWithEvent(
  activityId,
  productVersionId,
  questionId,
  index
) {
  return makeActionChain([
    () => actions.setMAPProductVersionQuestionIndex(index),
    () =>
      actions.createActivityProductVersionSessionEvent(
        activityId,
        productVersionId,
        questionId
      ),
    () => actions.scrollTop('.full-screen-overlay'),
  ]);
}

export function createReportsAndRefresh(organisationId, reportDetails) {
  return makeActionChain([
    () => actions.createReports(reportDetails),
    () => actions.getUserInfo(),
    () => actions.loadOrgSessionsRecent(organisationId),
  ]);
}

export function createJobReportsAndRefresh(
  organisationId,
  reportDetails,
  user
) {
  return makeActionChain([
    () => actions.createReports(reportDetails),
    () => actions.getUserInfo(),
    () => actions.loadProfileSessionsRecent(organisationId, user),
  ]);
}

export function goToMapProductVersionPage(page) {
  return makeActionChain([
    () => actions.setMapProductVersionPage(page),
    () => actions.scrollTop('.full-screen-overlay'),
  ]);
}

export function startMAPRealQuestions(
  activityId,
  productVersionId,
  newQuestionId,
  newIndex
) {
  return makeActionChain([
    () =>
      actions.updateActivityProductVersionSession(
        activityId,
        productVersionId,
        null,
        { real_questions_started: true }
      ),
    () => actions.startMapProductVersionTimer(),
    () => goToMapProductVersionPage(null),
    () =>
      setMAPProductVersionQuestionIndexWithEvent(
        activityId,
        productVersionId,
        newQuestionId,
        newIndex
      ),
  ]);
}

export function closeModalAndApplyTemplate(activityId, templateId) {
  return makeActionChain([
    () => modals.closeTopModal(),
    () => actions.applyTemplate(activityId, templateId),
    () => items.loadActivity(activityId),
  ]);
}

export function addGroupToOrganisationOpenGroup(
  organisationId,
  groupName,
  dataAnalyticsFilters
) {
  return makeActionChain([
    () => collections.addGroup(organisationId, groupName, dataAnalyticsFilters),
    group => items.loadGroup(group.id),
  ]);
}

export function addAdminToOrganisationAndResetForm(
  accountId,
  email,
  name,
  role
) {
  return makeActionChain([
    () => actions.addAdminToOrganisation(accountId, email, name, role),
  ]);
}

export function addGroupAndAddAllActivityUsersToGroup(
  organisationID,
  groupName,
  groupUsers
) {
  return makeActionChain([
    () => collections.addGroup(organisationID, groupName),
    group => actions.addUsersToGroup(group.id, groupUsers),
  ]);
}

export function updateCreditCostAndCloseModal(id, cost) {
  return makeActionChain([
    () => collections.updateCreditCost(id, cost),
    () => modals.closeTopModal(),
  ]);
}

export function addDiscountBandCollectionAndOpenEditModal(name) {
  return makeActionChain([
    () => collections.addDiscountBandCollection(name),
    discountBandCollection =>
      modals.openEditBulkDiscountBandModal(fromJS(discountBandCollection)),
  ]);
}

export function deleteDiscountBandAndCloseModal(id) {
  return makeActionChain([
    () => collections.deleteDiscountBandCollection(id),
    () => modals.closeTopModal(),
  ]);
}

export function pollOrgSessions(orgId, name, rate) {
  return dispatch =>
    dispatch(
      actions.setPoll(name, () => actions.loadOrgSessionsRecent(orgId), rate)
    );
}

export function pollProfileSessions(orgId, name, rate) {
  return dispatch =>
    dispatch(
      actions.setPoll(
        name,
        () => actions.loadProfileSessionsRecent(orgId),
        rate
      )
    );
}

function loadOrgProductsThenPoll(orgId, user, name, rate) {
  return dispatch =>
    dispatch(actions.clearPoll(name)).then(() =>
      dispatch(actions.loadOrgProducts(orgId, user)).then(() =>
        dispatch(actions.loadOrgSessions(orgId, user)).then(() =>
          dispatch(pollOrgSessions(orgId, name, rate))
        )
      )
    );
}

export function loadOrgData(orgId, user, name, rate) {
  return dispatch =>
    Promise.all([
      dispatch(actions.loadOrgUsers(orgId)),
      dispatch(actions.loadOrgFilters(orgId)),
      dispatch(loadOrgProductsThenPoll(orgId, user, name, rate)),
    ]);
}

const isGenerating = report =>
  ['UNPOPULATED', 'NOTSTARTED', 'PROGRESS'].includes(report.pdf_status);

export function pollGeneratingReports(reports) {
  return dispatch => {
    const generatingReportIds = reports
      .filter(isGenerating)
      .map(report => report.id);
    if (generatingReportIds.length) {
      dispatch(actions.getReports(generatingReportIds)).then(prevAction => {
        if (prevAction.type === actions.GET_REPORTS.SUCCESS) {
          reports = prevAction.payload;
        }
        setTimeout(() => dispatch(pollGeneratingReports(reports)), 2500);
      });
    }
  };
}

export function loadTeamReportsAndPoll(orgId, user) {
  return dispatch =>
    dispatch(actions.loadTeamReports(orgId, user)).then(prevAction => {
      if (prevAction.type === actions.LOAD_TEAM_REPORTS.SUCCESS) {
        const reports = prevAction.payload.flatMap(team => team.reports);
        dispatch(pollGeneratingReports(reports));
      }
    });
}

export function loadOrgProductsThenTeamReports(orgId, user) {
  return dispatch =>
    dispatch(actions.loadOrgFilters(orgId, { team: true })).then(() =>
      dispatch(actions.loadOrgProducts(orgId, user)).then(() =>
        dispatch(loadTeamReportsAndPoll(orgId, user))
      )
    );
}

export function loadOrgProductsThenProfileSessions(orgId, user) {
  return dispatch =>
    dispatch(actions.loadOrgFilters(orgId, { profile: true })).then(() =>
      dispatch(actions.loadOrgProducts(orgId, user)).then(() =>
        dispatch(actions.loadProfileSessions(orgId))
      )
    );
}

export const getStripeInvoiceAndRedirectToPayment = (
  organisationId,
  credits
) => dispatch => {
  dispatch(actions.getStripeInvoice(organisationId, credits)).then(
    prevAction => {
      const { payload: invoice } = prevAction;
      dispatch(
        modals.openPaymentInProgressModal(true, invoice.hosted_invoice_url)
      );
      const paymentWindow = window.open(invoice.hosted_invoice_url, '_blank');

      if (paymentWindow) {
        const interval = window.setInterval(() => {
          if (paymentWindow.closed) {
            dispatch(modals.closeModal(MODALS.PAYMENT_IN_PROGRESS_MODAL));
            window.clearInterval(interval);
          }
        }, 500);
      }
    }
  );
};

export const getStripeShopInvoiceAndRedirectToPayment = basketItemIds => dispatch => {
  dispatch(actions.getStripeShopInvoice()).then(prevAction => {
    const { payload: invoice } = prevAction;
    dispatch(
      modals.openPaymentInProgressModal(
        false,
        invoice.hosted_invoice_url,
        basketItemIds
      )
    );
    window.open(invoice.hosted_invoice_url, '_blank');
  });
};

export function addToBasketAndNavigateToBasket(shopItemOptionId) {
  return makeActionChain([
    () => shop.addToBasket(shopItemOptionId),
    () => modals.closeTopModal(),
    () => push('/page/basket'),
  ]);
}

export function addProductOrganisationsAndCloseTopModal(
  activityId,
  productOrganisationIds
) {
  return makeActionChain([
    () => actions.addProductOrganisations(activityId, productOrganisationIds),
    () => modals.closeTopModal(),
  ]);
}

export function inviteToStageTwoAndRefresh(activityId, sessionIds) {
  return makeActionChain([
    () => actions.inviteToStageTwo(activityId, sessionIds),
    () => collections.getActivityUsers(activityId),
  ]);
}

export const viewQuickSupportGuide = () => dispatch => {
  dispatch(actions.getQuickSupportGuide()).then(prevAction => {
    if (prevAction.type === actions.GET_QUICK_SUPPORT_GUIDE.SUCCESS) {
      window.open(prevAction.payload.url, '_blank');
    }
  });
};

export const generateTeamReportAndRefresh = (
  reportDetails,
  orgId,
  user,
  reportsSetter
) => dispatch => {
  dispatch(actions.generateTeamReport(reportDetails)).then(action => {
    action.type === actions.GENERATE_TEAM_REPORT.SUCCESS &&
      dispatch(loadOrgProductsThenTeamReports(orgId, user));
    reportsSetter(action.payload);
  });
};

export const generateProfileReportsAndRefresh = (
  reportDetails,
  orgId,
  reportsSetter
) => dispatch => {
  dispatch(actions.createReports(reportDetails)).then(action => {
    reportsSetter(action.payload);
    dispatch(pollProfileSessions(orgId, 'ProfileReportsPage', 5000));
  });
};

export const createPulseAndRedirect = (data, showFlash) => dispatch => {
  dispatch(actions.createPulse(data)).then(action => {
    action.type === actions.CREATE_PULSE.SUCCESS &&
      showFlash(action.payload.id);
  });
};

export const disagreeConsentAndRedirect = policyId => {
  return makeActionChain([
    () => actions.submitConsentDisagree(policyId),
    () => push('/logout'),
  ]);
};

export const agreeConsentAndRedirect = (policyId, redirectUrl) => {
  return makeActionChain([
    () => actions.submitConsentAgree(policyId),
    () => actions.getUserInfo(),
    () => push(redirectUrl),
  ]);
};

export const logoutAndResetInteractions = () => {
  return makeActionChain([
    () => actions.logout(),
    () => setLanguageSwitcherInteractedWith(false),
  ]);
};

export const setUiLanguageAndMaybeActivate = (langCode, url) => dispatch => {
  const shouldActivateLang =
    url &&
    (Object.keys(TRANSLATED_PATHS_ONLY_FOR_SWITCHABLE_USERS).includes(url) ||
      !url.startsWith('/page/'));
  const safeLangCode = getLangCodeWithFallback(langCode);

  if (shouldActivateLang) {
    return dispatch(setLanguageAndReload(safeLangCode, url));
  }
  return dispatch(setUiLanguage(safeLangCode));
};

export const navigateToPulseAndCloseModal = (pulseId, modalId) => {
  return makeActionChain([
    () => modals.closeModal(modalId),
    () => push(`/page/pulses/${pulseId}`),
  ]);
};

export const makeRequestAndShowStatus = (
  action,
  thenFunc,
  responseKey,
  successText,
  ...actionArgs
) => dispatch => {
  dispatch(action(...actionArgs))
    .then(response => thenFunc && thenFunc(response))
    .then(() =>
      dispatch(modals.openRequestStatusModal(responseKey, successText))
    )
    .catch(response => {
      dispatch(modals.openRequestStatusModal(responseKey, successText));
    });
};
