import { CONSENT_STATES } from '^/components/consent/constants';
import {
  AGE_CHOICES,
  EDUCATION_CHOICES,
  ETHNICITY_CHOICES,
  GEOGRAPHY_CHOICES,
  INDUSTRY_CHOICES,
  JOB_LEVEL_CHOICES,
  SEX_CHOICES,
} from '^/components/profile/choices';
import { LanguageCode } from '^/constants/routes';
import { DeepReadonly } from '^/types';

export type LanguageKeyedStrings = DeepReadonly<{
  [LanguageCode.EN_GB]: string;
  [LanguageCode.AR]: string;
}>;

export type InviteDefaults = DeepReadonly<{
  opening_message?: LanguageKeyedStrings;
}>;

export type IdAndName = DeepReadonly<{
  id: string;
  name: string;
}>;

export type Organisation = IdAndName &
  Readonly<{
    tax_rate: number;
  }>;
export type Activity = IdAndName &
  Readonly<{
    mine: boolean;
    shared_with_me: boolean;
  }>;
export type Group = IdAndName;
export type Product = IdAndName;

export type OrgFilters = DeepReadonly<{
  activities: Activity[];
  groups: Group[];
  products: Product[];
}>;

export type Uuid = string;
export type Url = string;

export type OrgUser = DeepReadonly<{
  id: Uuid;
  full_name: string;
  usergroup_set: string[];
  activity_users: Array<{
    activity: Uuid;
  }>;
  email: string;
}>;

export type OrgUsers = DeepReadonly<{
  users: OrgUser[];
}>;

export type ReportTeamTemplate = DeepReadonly<{
  id: Uuid;
  name: string;
  code_display: string;
  product_set: Uuid[];
  cost: number;
  lang_code: LanguageCode;
}>;

export type ReportTeam = DeepReadonly<{
  id: Uuid;
  name: string;
  report_template: ReportTeamTemplate;
  reports: Array<{
    id: Uuid;
    created: string;
    created_by_user: Uuid;
    template: Uuid;
    pdf_status: PdfStatus;
  }>;
}>;

export type ReportJobProfile = {
  id: Uuid;
  completed: string;
  user: {
    id: Uuid;
    full_name: string;
    email: string;
  };
  profile_name: string;
  reports: JobProfileTemplate;
};
export type TeamReportsState = ReadonlyArray<ReportTeam>;

export type ReportTemplate = DeepReadonly<{
  id: Uuid;
  code: string;
  name: string;
  credit_cost: number;
  requires_complete_session: boolean;
  requires_job_level: boolean;
  is_team: boolean;
  is_job_match: boolean;
  is_job_match_related: boolean;
  lang_code: LanguageCode;
}>;

export enum PdfStatus {
  Unpopulated = 'UNPOPULATED',
  NotStarted = 'NOTSTARTED',
  Progress = 'PROGRESS',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
}

export type Report = DeepReadonly<{
  id: Uuid;
  pdf_status: PdfStatus;
  template: Uuid;
  created?: string;
  job_match_report?: {
    profile_name: string;
  } | null;
}>;

export type ReportsState = ReadonlyArray<Report>;

export type ActivityType = 'PSYCHOMETRIC' | 'THREE_SIXTY' | 'PROFILER';

export interface ProductVariant {
  id: Uuid;
  code: string;
  report_templates: ReadonlyArray<ReportTemplate>;
}

export interface ComparisonGroup {
  id: Uuid;
  name: string;
}

export type ProductOrganisationReportTemplate = DeepReadonly<{
  credit_cost: number | null;
  report_template: ReportTemplate;
}>;

export type ProductOrganisation = DeepReadonly<{
  product: {
    id: Uuid;
    name: string;
    code: string;
    icon: Url;
    activity_type: ActivityType;
    requires_accreditation: boolean;
  };
  productorganisationreporttemplate_set: Array<
    ProductOrganisationReportTemplate
  >;
  comparison_groups: Array<ComparisonGroup>;
  product_variant: ProductVariant | null;
}>;

export interface ActivityProductVersionSession {
  id: Uuid;
  created: string;
  completed: string;
  user: Uuid;
  reports: Report[];
  has_comparison_group: boolean;
  is_missing_line_manager: boolean;
}

export type OrgActivity = DeepReadonly<{
  id: Uuid;
  shared_with_me: boolean;
  mine: boolean;
  activity_product_version_items: Array<{
    id: Uuid;
    product_variant: Uuid | null;
    product_version: {
      product: Uuid;
    };
    pulse: Pulse;
    activity_product_version_sessions: ActivityProductVersionSession[];
  }>;
}>;

export type OrgSessions = DeepReadonly<{
  id: Uuid;
  name: string;
  credit: number;
  has_unlimited_credits: boolean;
  is_using_credit: boolean;
  accredited_products: Uuid[];
  productorganisation_set: ProductOrganisation[];
  activities: OrgActivity[];
}>;

export type AllOrgs = Organisation[] | null;

export type ReportEmailDefault = DeepReadonly<{
  translations: {
    [LanguageCode.EN_GB]: {
      subject: string;
      content: string;
      link_text: string;
    };
    [LanguageCode.AR]?: {
      subject: string;
      content: string;
      link_text: string;
    };
  };
}>;

export type ReportEmailDefaults = DeepReadonly<{
  self: ReportEmailDefault;
  additional_recipients: ReportEmailDefault;
  respondents: ReportEmailDefault;
  created_by_admin: ReportEmailDefault;
  created_by_admin_360: ReportEmailDefault;
  recipients: ReportEmailDefault;
  recipients_360: ReportEmailDefault;
  ACTIVITY_REPORTS_READY: ReportEmailDefault;
  send_individual_reports_to_self: ReportEmailDefault;
  send_team_to_self: ReportEmailDefault;
  send_individual_reports_to_respondents: ReportEmailDefault;
  additional_individual_reports_recipients: ReportEmailDefault;
  additional_team_reports_recipients: ReportEmailDefault;
}>;

export enum ShopItemOptionType {
  Accreditation = 'ACCREDITATION',
  SelfVerify = 'SELF_VERIFY',
  Bookable = 'BOOKABLE',
  FeedbackSession = 'FEEDBACK_SESSION',
  Product = 'PRODUCT',
}

export const SHOP_ITEM_OPTION_TYPE_CHOICE: Record<
  ShopItemOptionType,
  string
> = {
  [ShopItemOptionType.Accreditation]: 'Accreditation',
  [ShopItemOptionType.SelfVerify]: 'Self Verify',
  [ShopItemOptionType.Bookable]: 'Bookable',
  [ShopItemOptionType.FeedbackSession]: 'Feedback Session',
  [ShopItemOptionType.Product]: 'Product',
};

export interface ShopItemOption {
  id: string;
  type: ShopItemOptionType;
  cost: null | string;
  label: null | string;
  description: null | string;
  link_url: null | string;
  product_variant: null | Uuid;
}

export interface ShopItemProduct {
  id: Uuid;
  requires_accreditation: boolean;
}

export interface ShopItem {
  id: string;
  name: string;
  description: string;
  published: boolean;
  product: null | ShopItemProduct;
  image: null | string;
  options: ReadonlyArray<ShopItemOption>;
}

export interface ShopItemCategory {
  id: Uuid;
  title: string;
  description: string;
  shopitem_set: ReadonlyArray<ShopItem>;
}

export type ShopItemsState = ReadonlyArray<ShopItemCategory> | null;

export type PurchasedShopItemsState = ReadonlyArray<ShopItemCategory> | null;

export interface AnalyticsSessionUser {
  id: Uuid;
  email: string;
  full_name: string;
  organisation: { name: string };
  sex: keyof typeof SEX_CHOICES;
  geography: keyof typeof GEOGRAPHY_CHOICES;
  age: keyof typeof AGE_CHOICES;
  education: keyof typeof EDUCATION_CHOICES;
  job_level: keyof typeof JOB_LEVEL_CHOICES;
  ethnicity: keyof typeof ETHNICITY_CHOICES;
  industry: keyof typeof INDUSTRY_CHOICES;
}

export interface AnalyticsPsychoResult {
  score: number;
  level?: string;
  sten?: number;
  rating?: number;
}

export interface Analytics360Result {
  self_mean: string;
  rater_mean: string;
  self_rater_difference: string;
}

export interface AnalyticsFactorResult extends AnalyticsPsychoResult {
  factor: string;
  code?: string;
}

export interface AnalyticsFactorDISCResult {
  factor: string;
  code: string;
  score: number;
}

export interface AnalyticsCodeResult extends Analytics360Result {
  code: string;
}

export interface AnalyticsSessionDISCChunk {
  item: AnalyticsSessionDISC;
  index: number;
  key: number;
}
export interface AnalyticsSessionChunk {
  item: AnalyticsSession;
  index: number;
  key: number;
}

export type AnalyticsFactorResults = ReadonlyArray<AnalyticsFactorResult>;
export type AnalyticsFactorDISCResults = ReadonlyArray<
  AnalyticsFactorDISCResult
>;
export type AnalyticsCodeResults = ReadonlyArray<AnalyticsCodeResult>;

export type GenericAnalyticsFilterItem = {
  id: Uuid;
  name: string;
  organisation__name?: string;
  tax_rate?: number;
};
export type GenericAnalyticsFilterList = ReadonlyArray<
  GenericAnalyticsFilterItem
>;

export interface PulseBehaviourHierarchyOption {
  id: Uuid;
  name: string;
  price: number;
  icon: string;
}

export type PulseBehaviourHierarchyOptions = ReadonlyArray<
  PulseBehaviourHierarchyOption
>;

type AnalyticsFiltersList =
  | 'activity'
  | 'organisation'
  | 'group'
  | 'product'
  | 'user'
  | 'sex'
  | 'age'
  | 'ethnicity'
  | 'job_level'
  | 'industry'
  | 'geography';

export type AnalyticsFilters = {
  [AnalyticFilter in AnalyticsFiltersList]: GenericAnalyticsFilterList;
} & {
  filter_profile: ReadonlyArray<SelectedFilterProfileData>;
};

export interface SelectedFilterProfileData extends GenericAnalyticsFilterItem {
  filters: Array<string>;
  product?: Uuid;
}

export type AnalyticsFilterItem =
  | GenericAnalyticsFilterItem
  | SelectedFilterProfileData;

export type AnalyticsAggregateResults = ReadonlyArray<{
  factor: string;
  code?: string;
  mean: AnalyticsPsychoResult;
  min: AnalyticsPsychoResult;
  max: AnalyticsPsychoResult;
}>;

export type AnalyticsAggregateExtraResults = {
  self_image_dimensions_order?: ReadonlyArray<string>;
  self_image_style_name?: string;
  self_image_pattern?: string;
  self_image_pattern_text?: string;
};

export interface PrimaryCompetency {
  code: string;
  name: string;
  secondary_competencies: ReadonlyArray<{
    code: string;
    name: string;
  }>;
}
export type ProductType =
  | 'PSYCAP_POTENTIAL'
  | 'POSITIVE_RESILIENCE_PROFILER'
  | 'DISC' /* | 'THREE_SIXTY' | 'EAP' | 'ISP'  | 'MAP'*/;

export interface AnalyticsProduct {
  id: Uuid;
  name: string;
  type: ProductType;
  activity_type: 'THREE_SIXTY' | 'PSYCHOMETRIC';
  levels_by_factor: { [key: string]: string[] };
  primary_competencies?: ReadonlyArray<PrimaryCompetency>;
  likert_maximum: null | number;
}

export interface Analytics360Results {
  results: ReadonlyArray<AnalyticsCodeResult>;
  summary: Analytics360Result;
  total_raters_completed: number;
  benchmark_mean_avg: number;
}

export interface AnalyticsSession {
  user: AnalyticsSessionUser;
  results: AnalyticsFactorResults | Analytics360Results;
  completed: string;
  self_and_lm_completed: null | string;
  activity: { name: string };
  extra_results?: AnalyticsFactorResults;
}

export interface AnalyticsSessionDISC {
  user: AnalyticsSessionUser;
  results: AnalyticsFactorDISCResults;
  completed: string;
  self_and_lm_completed: null | string;
  activity: { name: string };
  extra_results?: any;
}

export interface Analytics360AggregateResult {
  code: string;
  mean: Analytics360Result;
  min: Analytics360Result;
  max: Analytics360Result;
}

export interface Analytics360AggregateResults {
  results: ReadonlyArray<Analytics360AggregateResult>;
  summary: Analytics360AggregateResult;
  total_raters_completed: number;
  benchmark_mean_avg: number;
}

export interface SubAggregate {
  key: string;
  value: Uuid;
  filtersState?: AnalyticsFiltersState;
}

export interface AnalyticsFiltersState {
  product?: AnalyticsProduct;
  filters: AnalyticsFilters;
  sessions: null | {
    aggregate: AnalyticsAggregateResults | Analytics360AggregateResults;
    aggregate_extra?: AnalyticsAggregateExtraResults;
    count: number;
    results: ReadonlyArray<AnalyticsSession>;
  };
}

export type AnalyticsSessionsState = ReadonlyArray<ReadonlyArray<
  AnalyticsSession
> | null>;

export interface AnalyticsAppliedFilters {
  organisation?: Uuid[];
  activity?: Uuid[];
  product?: Uuid | null;
  group?: Uuid[];
  user?: Uuid[];
  sex?: string[];
  age?: string[];
  ethnicity?: string[];
  job_level?: string[];
  industry?: string[];
  geography?: string[];
  results_ranges?: string[] | string[][];
  filter_profile?: string[];
}

export interface AnalyticsAppliedFiltersWithResultsRangesFlattened
  extends Omit<AnalyticsAppliedFilters, 'results_ranges'> {
  results_ranges: string[];
}

export type TeamSession = {
  id: Uuid;
  completed: string;
  user: {
    id: Uuid;
    full_name: string;
    email: string;
    profile_picture: string | null;
    usergroup_set: ReadonlyArray<{
      id: Uuid;
      name: string;
    }>;
  };
  activity_product_version: {
    activity: {
      id: Uuid;
      name: string;
    };
  };
  reports: ReadonlyArray<{
    template: {
      id: Uuid;
      lang_code: LanguageCode;
    };
  }>;
};

export type TeamSessions = ReadonlyArray<TeamSession>;

export type JobProfileTemplate = ReadonlyArray<{
  id: Uuid;
  pdf_status: PdfStatus;
  template: {
    id: Uuid;
    lang_code: LanguageCode;
    credit_cost: number;
  };
}>;

export type Profile = {
  id: Uuid;
  profile_name: string;
  success_profile: {
    id: Uuid;
    verified_by: {
      id: string;
      full_name: string;
      email: string;
    };
  } | null;
  activity: {
    id: Uuid;
    name: string;
    created_by_user: {
      id: Uuid;
      full_name: string;
      email: string;
    };
    activity_product_version_items: [
      {
        id: Uuid;
        product_version: {
          product: {
            id: Uuid;
            type: ProductType;
          };
        };
        activity_product_version_sessions: [
          {
            id: Uuid;
            completed: string;
            reports: [
              {
                id: Uuid;
                pdf_status: PdfStatus;
                template: {
                  id: Uuid;
                  lang_code: LanguageCode;
                  credit_cost: number;
                };
                created?: string;
                job_match_report?: {
                  profile_name: string;
                } | null;
              }
            ];
          }
        ];
      }
    ];
  };
};

export type Profiles = ReadonlyArray<Profile>;

export type ProfileRespondentSession = {
  id: Uuid;
  completed: string;
  user: {
    id: Uuid;
    full_name: string;
    email: string;
    profile_picture: string | null;
    usergroup_set: ReadonlyArray<{
      id: Uuid;
      name: string;
    }>;
  };
  activity_product_version: {
    activity: {
      id: Uuid;
      name: string;
    };
    product_version: {
      product: {
        id: Uuid;
      };
    };
  };
  reports: ReadonlyArray<{
    id: Uuid;
    pdf_status: PdfStatus;
    template: {
      id: Uuid;
      lang_code: LanguageCode;
    };
    report_generation_batch: {
      comparison_group: {
        entity: {
          id: Uuid;
        };
      };
    };
  }>;
};

export type ProfileRespondentSessions = ReadonlyArray<ProfileRespondentSession>;

export interface HeatmapRow {
  appliedFilters: AnalyticsAppliedFilters;
  filtersState: AnalyticsFiltersState;
  children: HeatmapRow[];
  selectedProfiles?: Record<Uuid, SelectedFilterProfileData>;
  previewFilterRanges?: Array<Array<string>>;
  loading?: boolean;
}

export enum PulseStatus {
  INVITED = 'INVITED',
  PULSING = 'PULSING',
  CAN_START = 'CAN START',
  CAN_NOT_START = 'CAN NOT START',
  ENDED = 'ENDED',
  ENDING = 'ENDING',
  NOT_STARTED = 'NOT_STARTED',
  INCOMPLETE = 'INCOMPLETE',
}

export enum PulseFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}

export enum PulseDisplayFrequency {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly (4 weeks)',
}

export interface UserPulseDetail {
  id: Uuid;
  status: PulseStatus;
  end_date: string | undefined;
  user: {
    id: Uuid;
    email: string;
  };
  score_frequency: PulseFrequency;
  score_frequency_count: number;
  pulse_sub_behaviours: ReadonlyArray<{
    id: Uuid;
    name: string;
    description: string;
    userpulsescore_set: ReadonlyArray<UserPulseRaterDailyAverageScore> | [];
    pulse_behaviour: {
      id: Uuid;
      name: string;
    };
  }>;
  pulse: {
    created: string;
    activity_product_version: {
      activity: {
        name: string;
      };
    };
    is_self_only: boolean;
  };
  userpulserater_set: ReadonlyArray<{
    id: Uuid;
    email: string | null;
    is_self: boolean;
    is_deleted?: boolean;
  }>;
  subscription: {
    frequency: PulseFrequency;
    frequency_count: number;
    reminder_time_utc: string;
  };
}

type SurveyStatus =
  | 'NOT_STARTED'
  | 'PARTIALLY_COMPLETED'
  | 'COMPLETED'
  | 'OPT_OUT'
  | 'PARTIALLY_COMPLETED_STAGE_1'
  | 'OPT_OUT_STAGE_1'
  | 'COMPLETED_STAGE_1'
  | 'INVITED_TO_STAGE_2'
  | 'PARTIALLY_COMPLETED_STAGE_2'
  | 'COMPLETED_STAGE_2';

export interface PulseSession {
  user: Uuid;
  survey_status: SurveyStatus;
  reports: [
    {
      pdf_status: PdfStatus;
    }
  ];
}

export interface PulseSubscriptionDetails {
  product: string;
  price: number;
  tax_rate: number;
  used_names: ReadonlyArray<string>;
}

export interface UserPulse {
  id: Uuid;
  user: {
    id: Uuid;
    full_name: string;
    email: string;
  };
  number_of_raters: number | string;
  pulse_sub_behaviours: ReadonlyArray<{ name: string; id: string }> | null;
  subscription?: { id: Uuid };
  frequency: string;
  frequency_count: number;
  status: PulseStatus;
  created: string;
  end_date: string | undefined;
}

export interface PulseSubscription {
  id: Uuid;
  name: string;
}

export interface Pulse {
  id: Uuid;
  created: string;
  name?: string;
  frequency: PulseFrequency;
  frequency_count: number;
  reminder_time_utc: string;
  userpulse_set: UserPulse[] | null;
  activity_product_version: null | {
    activity_product_version_sessions: ReadonlyArray<PulseSession>;
    product_version: {
      product: {
        id: Uuid;
        name: string;
        activity_type: ActivityType;
      };
    };
    activity: {
      id: Uuid;
      name: string;
      users: ReadonlyArray<{
        completed: string | null;
        created: string | null;
        user: {
          id: Uuid;
          email: string;
          full_name: string;
        };
      }>;
    };
  };
  organisation?: {
    name: string;
  };
  pulse_behaviour_hierarchy?: {
    name: string;
  };
  pulse_subscription_details: PulseSubscriptionDetails;
  pulsesubscription_set?: PulseSubscription[];
}

export interface OrgFlexiPulseNames {
  names: string[];
}

export interface UserPulseRater {
  id: Uuid;
  is_self: boolean;
  rated_today: boolean;
  is_deleted: boolean;
  user_pulse: {
    user: {
      full_name: string;
      organisation: {
        brand_logo: string;
      };
    };
    pulse: {
      created: string;
      reminder_time_utc: string;
      frequency: PulseFrequency;
      frequency_count: number;
    };
    pulse_sub_behaviours: ReadonlyArray<{
      pulseitem_set: ReadonlyArray<{
        id: Uuid;
        text: string;
      }>;
    }>;
  };
}

export interface UserPulseRaterDailyAverageScore {
  created: string;
  rater_id: Uuid;
  average_score: number;
}

export interface BehaviourOptions {
  pulsebehaviour_set: [
    {
      id: Uuid;
      name: string;
      description: string;
      pulsesubbehaviour_set: BehaviourDetail[];
    }
  ];
}

export interface BehaviourDetail {
  id: Uuid;
  name: string;
  description: string;
}

export interface RatingsData {
  created: string | null;
  raterScoreId: null | ReadonlyArray<{
    rater_id: Uuid;
    score: number;
  }>;
}

export type Frequency = { type: PulseFrequency; count: number };

export type ChartDataStructure = {
  xAxisLabels: (string | null)[];
  frequencyAverages: ReadonlyArray<number | string | null>;
  selfData: ReadonlyArray<string | number | null>;
  ratersData: ReadonlyArray<ReadonlyArray<string | number | null>>;
  dataBlockCount: number;
  dailyAverages: ReadonlyArray<number | null>;
  dailyRanges: ReadonlyArray<number>;
  dailyRaterCount: ReadonlyArray<number | null>;
  startDate: string;
};

export interface ConsentResponse {
  state: CONSENT_STATES;
  date: string;
}

export interface PolicyVersionContentItem {
  id: Uuid;
  text: string;
  bold: boolean;
  bullet_point: boolean;
}

export interface ConsentPolicy {
  id: Uuid;
  title: string;
  policyversioncontentitem_set: ReadonlyArray<PolicyVersionContentItem>;
  consentable_set: ReadonlyArray<{ id: Uuid; text: string }>;
  latest_response: ConsentResponse | null;
}

export interface FilterProfileDetail {
  id: Uuid;
  name: string;
  filters: string[];
  created_by: {
    id: Uuid;
    full_name: string;
  };
  organisation: {
    id: Uuid;
    name: string;
  };
  product: {
    id: Uuid;
    type: ProductType;
  };
  shared_with_me?: boolean;
}

export interface FilterProfile {
  id: Uuid;
  name: string;
  filters: string[];
  shared_with_me: boolean;
  can_edit: boolean;
}

export interface SuccessProfileItem {
  factor: string;
  factor_label: string;
  score: number;
  rank: number;
  profile_section: string;
  range_low?: number | null;
  range_high?: number | null;
}

export interface SuccessRanges {
  low: number;
  high: number;
}

export interface Ranges {
  CRITICAL: {
    rank_low: number;
    rank_high: number;
    range_low: number;
    range_high: number;
  };
  IMPORTANT: {
    rank_low: number;
    rank_high: number;
    range_low: number;
    range_high: number;
  };
}

export interface DefaultRanges {
  potential: Ranges;
  competencies: Ranges;
}

export type SuccessProfileSection = Array<SuccessProfileItem>;

export interface SuccessProfile {
  id: Uuid;
  profile_name: string;
  can_verify: boolean;
  default_ranges: DefaultRanges;
  number_of_raters: number;
  potential: SuccessProfileSection;
  competencies: SuccessProfileSection;
  motivators: SuccessProfileSection;
}
